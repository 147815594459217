<template>
  <div class="dealOrder">
    <div class="dealOrderTitle">
      <div class="myDealOrder">
        <p>产能采购协议</p>
      </div>
    </div>
    <div class="searchList">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-input
            v-model="searchForm.commodityName"
            placeholder="商品分类"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            class="width-100"
            v-model="searchForm.startTime"
            type="datetime"
            placeholder="开始时间"
          ></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            class="width-100"
            v-model="searchForm.endTime"
            type="datetime"
            placeholder="结束时间"
          ></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-select v-model="searchForm.status" placeholder="请选择状态">
            <el-option
              v-for="item in statusOptions"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" border>
      <el-table-column prop="orderId" label="协议号"></el-table-column>
      <el-table-column prop="name" label="商品分类"></el-table-column>
      <el-table-column prop="price" label="商品总额"></el-table-column>
      <el-table-column prop="fee" label="手续费"></el-table-column>
      <el-table-column prop="value" label="状态"></el-table-column>
      <el-table-column prop="enterpriseName" label="公司"></el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime | DateFormate }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="190">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 2"
            type="danger"
            size="mini"
            @click="comfirmOrder(scope.row.orderId)"
            >支付剩余款</el-button
          >
          <el-button size="mini" @click="viewDetails(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-row class="pagination">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      v-dialogDrag
      title="产能采购协议详情"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="670px"
      :before-close="handleClose"
    >
      <div class="orderDetails">
        <el-row>
          <el-col :span="4">
            <span class="listTitle">协议号：</span>
          </el-col>
          <el-col :span="20">
            <span class="listContent">{{ orderDetails.orderId }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">商品分类：</span>
          </el-col>
          <el-col :span="20">
            <span class="listContent">{{ orderDetails.name }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">数量：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.quantity }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">产地：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.origin }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">交易金额：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.cjPrice }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">手续费：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.buyFee }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">成交价：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.tradePrice }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">预付款：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.yfFunds }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">仓库：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.warehouse }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">交易单位：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.tradeUnitName }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">挂牌基价：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.gpPrice }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">付款方式：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.zfType }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">付款比例：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.payRate }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">交货期：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.jhDate }}</span>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payDialogVisible = false">下载协议</el-button>
        <el-button
          v-if="orderDetails.status === 2"
          type="danger"
          @click="comfirmOrder(orderDetails.orderId)"
          >支付剩余款</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      tableData: [
        // {
        //   orderId: "clw009999",
        //   name: "钢材",
        //   mark: "Q235B",
        //   size: "1.5*1000*0",
        //   location: "梅钢",
        //   weight: "30.000",
        //   price: "￥138600.00",
        //   fee: "￥0.00",
        //   value: "已付履约订金",
        //   status: 2,
        //   basicPrice: "4620.00",
        //   unitPrice: "4620.00",
        //   enterpriseName: "天钢集团",
        //   shouldPay: "￥138660.00",
        //   deliveryDate: "2019-08-31",
        //   paymentRatio: "100%定金",
        //   bill: "天津稀贵有限公司",
        //   createTime: 1583465849000
        // },
        // {
        //   orderId: "clw009999",
        //   name: "钢材",
        //   mark: "Q235B",
        //   size: "1.5*1000*0",
        //   location: "梅钢",
        //   weight: "30.000",
        //   price: "￥138600.00",
        //   fee: "￥0.00",
        //   value: "已付履约订金",
        //   status: 2,
        //   basicPrice: "4620.00",
        //   unitPrice: "4620.00",
        //   enterpriseName: "天钢集团",
        //   shouldPay: "￥138660.00",
        //   deliveryDate: "2019-08-31",
        //   paymentRatio: "100%定金",
        //   bill: "天津稀贵有限公司",
        //   createTime: 1583465849000
        // }
      ],
      searchForm: {
        commodityName: null,
        startTime: null,
        endTime: null,
        status: null
      },
      statusOptions: [
        // {
        //   value: "选项1",
        //   label: "已付款"
        // },
        // {
        //   value: "选项2",
        //   label: "未付款"
        // }
      ],
      current: 1,
      pageSize: 10,
      totalPage: null,
      orderDetails: {},
      dialogVisible: false
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getDatas();
    this.getBuyOrderStatus();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    resetSearch() {
      this.searchForm = {
        name: null,
        startTime: null,
        endTime: null,
        status: null
      };
      this.getDatas();
    },
    getBuyOrderStatus() {
      http.postFront(protocolFwd.param_selectBuyOrderStatus).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.statusOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getDatas() {
      protocolFwd.param_queryBuyerAgreement.param.commodityName = this
        .searchForm.commodityName
        ? this.searchForm.commodityName
        : null;
      protocolFwd.param_queryBuyerAgreement.param.startTime = this.searchForm
        .startTime
        ? this.searchForm.startTime
        : null;
      protocolFwd.param_queryBuyerAgreement.param.endTime = this.searchForm
        .endTime
        ? this.searchForm.endTime
        : null;
      protocolFwd.param_queryBuyerAgreement.param.status = this.searchForm
        .status
        ? this.searchForm.status
        : null;
      protocolFwd.param_queryBuyerAgreement.param.page = this.current - 1;
      protocolFwd.param_queryBuyerAgreement.param.size = this.pageSize;
      http.postFront(protocolFwd.param_queryBuyerAgreement).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableData = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    viewDetails(item) {
      this.dialogVisible = true;
      protocolFwd.param_queryBuyerAgreementDetail.param.orderId = item.orderId;
      http
        .postFront(protocolFwd.param_queryBuyerAgreementDetail)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.orderDetails = value;
            this.orderDetails.status = item.status;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.orderDetails = {};
    },
    comfirmOrder(id) {
      this.$confirm("请确认是否支付剩余款？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_payLastOrder.param.buyOrderId = id;
          http.postFront(protocolFwd.param_payLastOrder).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.dialogVisible = false;
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
      // 演示用
      // this.$confirm("请确认是否支付尾款？", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning"
      // })
      //   .then(() => {
      //     this.dialogVisible = false;
      //     this.$EL_MESSAGE("尾款支付成功");
      //   })
      //   .catch(() => {});
    }
  }
};
</script>
<style scoped>
.dealOrder {
  padding: 0 15px;
}
.dealOrderTitle {
  overflow: hidden;
}
.dealOrder .searchList {
  margin-bottom: 10px;
}
.dealOrder .myDealOrder {
  float: left;
  line-height: 40px;
}
.dealOrderTitle .myDealOrder p {
  font-size: 16px;
}
.orderDetails {
  line-height: 35px;
}
.orderDetails .listTitle {
  display: block;
  text-align: right;
}
.orderDetails .listContent {
  margin-left: 5px;
}
.dealOrder .pagination {
  margin-top: 15px;
}
</style>
